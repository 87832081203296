export const legacyTiers = [
  {
    name: "Solo",
    id: "tier-solo-legacy",
    href: "#",
    monthly: {
      stripeName: "solo",
      priceDisplay: "$15",
      price: 14.99,
    },
    yearly: {
      stripeName: "solo_yearly",
      priceDisplay: "$152",
      price: 152,
      price_per_month: "$12",
    },
    description: "For new projects that require up to 1 article per week.",
    mostPopular: false,
    documents: "10",
    users: "1",
    order: 1,
    features: {
      docSharing: false,
      wordpress: true,
      googleDocs: false,
      serpAnalysis: true,
      competitiveIntelligence: true,
      outlineBuilder: true,
      contentOptimization: true,
      multiQueryDocuments: false,
      seoAnalytics: false,
      automaticContentBrief: true,
      aiTools: true,
      languages: "20+",
      collaboration: false,
      chromeExtension: true,
      wordpressPlugin: true,
      googleDocsPlugin: false,
      prioritySupport: false,
      dedicatedAccountManagerAndCustomOnboarding: false,
      customContractsAndSecurityCompliance: false,
      llmOptionality: false,
    },
    searchQueries: 10,
    aiWordCount: "20,000",
    display: false,
    apiUsage: "Up to 500 URLs/month",
    gscDomainLimit: 0,
  },
  {
    name: "Solo",
    id: "tier-solo",
    href: "#",
    monthly: {
      stripeName: "solo_monthly_4",
      priceDisplay: "$15",
      price: 14.99,
    },
    yearly: {
      stripeName: "solo_yearly_4",
      priceDisplay: "$152",
      price_per_month: "$12",
      price: 152,
    },
    description: "For new projects that require up to 1 article per week.",
    mostPopular: false,
    documents: "4",
    users: "1",
    order: 1,
    features: {
      docSharing: false,
      wordpress: true,
      googleDocs: false,
      serpAnalysis: true,
      competitiveIntelligence: true,
      outlineBuilder: true,
      contentOptimization: true,
      multiQueryDocuments: false,
      seoAnalytics: false,
      automaticContentBrief: true,
      aiTools: true,
      languages: "20+",
      collaboration: false,
      chromeExtension: true,
      wordpressPlugin: true,
      googleDocsPlugin: false,
      prioritySupport: false,
      dedicatedAccountManagerAndCustomOnboarding: false,
      customContractsAndSecurityCompliance: false,
      llmOptionality: false,
    },
    searchQueries: 4,
    aiWordCount: "20,000",
    display: false,
    apiUsage: "Up to 500 URLs/month",
    gscDomainLimit: 0,
  },
  {
    name: "Team",
    id: "tier-team-legacy",
    href: "#",
    monthly: {
      stripeName: "team_monthly_115",
      priceDisplay: "$115",
      price: 114.99,
      lifetimePlan: "team_lifetime",
    },
    yearly: {
      stripeName: "team_yearly_1173",
      priceDisplay: "$1173",
      price: 1173,
      price_per_month: "$97",
      lifetimePlan: "team_lifetime_yearly",
    },
    description: "For teams that need more flexibility and collaboration.",
    mostPopular: false,
    documents: "Unlimited",
    users: "3 ($25/mo per extra)",
    order: 3,
    features: {
      docSharing: true,
      wordpress: true,
      googleDocs: true,
      serpAnalysis: true,
      competitiveIntelligence: true,
      outlineBuilder: true,
      contentOptimization: true,
      multiQueryDocuments: "5",
      seoAnalytics: "Unlimited Domains and Multiple Accounts",
      automaticContentBrief: true,
      aiTools: true,
      languages: "20+",
      collaboration: true,
      chromeExtension: true,
      wordpressPlugin: true,
      googleDocsPlugin: true,
      prioritySupport: true,
      dedicatedAccountManagerAndCustomOnboarding: false,
      customContractsAndSecurityCompliance: false,
      llmOptionality: false,
    },
    searchQueries: "Unlimited",
    aiWordCount: "Unlimited",
    display: false,
    apiUsage: "Up to 500 URLs/month",
    gscDomainLimit: Infinity,
  },
];

export const tiers = [
  {
    name: "Free",
    id: "tier-free-trial",
    href: "#",
    monthly: {
      stripeName: "free_trial",
      priceDisplay: "$0",
      price: 0,
    },
    yearly: {
      stripeName: "free_trial_yearly",
      priceDisplay: "$0",
      price: 0,
      price_per_month: "$0",
    },
    description: "For new users who want to try out the platform.",
    mostPopular: false,
    documents: "1",
    users: "1",
    order: 0,
    features: {
      docSharing: false,
      wordpress: false,
      googleDocs: false,
      serpAnalysis: false,
      competitiveIntelligence: false,
      outlineBuilder: false,
      contentOptimization: false,
      multiQueryDocuments: false,
      seoAnalytics: false,
      automaticContentBrief: false,
      aiTools: false,
      languages: "20+",
      collaboration: false,
      chromeExtension: false,
      wordpressPlugin: false,
      googleDocsPlugin: false,
      prioritySupport: false,
      dedicatedAccountManagerAndCustomOnboarding: false,
      customContractsAndSecurityCompliance: false,
      llmOptionality: false,
    },
    searchQueries: 1,
    aiWordCount: "1,000",
    display: false,
    apiUsage: "Up to 500 URLs/month",
    gscDomainLimit: 0,
  },
  {
    name: "Basic",
    id: "tier-basic",
    href: "#",
    monthly: {
      stripeName: "basic_monthly_45",
      priceDisplay: "$45",
      price: 44.99,
      lifetimePlan: "basic_lifetime",
    },
    yearly: {
      stripeName: "basic_yearly_459",
      priceDisplay: "$459",
      price: 459,
      price_per_month: "$37",
      lifetimePlan: "basic_lifetime_yearly",
    },
    description: "For organizations with a content plan and SEO goals.",
    mostPopular: false,
    documents: "30",
    users: "1",
    order: 2,
    features: {
      docSharing: true,
      wordpress: true,
      googleDocs: false,
      serpAnalysis: true,
      competitiveIntelligence: true,
      outlineBuilder: true,
      contentOptimization: true,
      multiQueryDocuments: "5",
      seoAnalytics: "1 Domain",
      automaticContentBrief: true,
      aiTools: true,
      languages: "20+",
      collaboration: true,
      chromeExtension: true,
      wordpressPlugin: true,
      googleDocsPlugin: false,
      prioritySupport: false,
      dedicatedAccountManagerAndCustomOnboarding: false,
      customContractsAndSecurityCompliance: false,
      llmOptionality: false,
    },
    searchQueries: 30,
    aiWordCount: "Unlimited",
    display: true,
    apiUsage: "Up to 500 URLs/month",
    gscDomainLimit: 1,
  },
  {
    name: "Team",
    id: "tier-team",
    href: "#",
    monthly: {
      stripeName: "team_9_monthly_115",
      priceDisplay: "$115",
      price: 114.99,
      lifetimePlan: "team_lifetime",
    },
    yearly: {
      stripeName: "team_9_yearly_1173",
      priceDisplay: "$1173",
      price: 1173,
      price_per_month: "$97",
      lifetimePlan: "team_lifetime_yearly",
    },
    description: "For teams that need more flexibility and collaboration.",
    mostPopular: false,
    documents: "Unlimited",
    users: "3 ($25/mo per extra, up to 9)",
    order: 3,
    features: {
      docSharing: true,
      wordpress: true,
      googleDocs: true,
      serpAnalysis: true,
      competitiveIntelligence: true,
      outlineBuilder: true,
      contentOptimization: true,
      multiQueryDocuments: "5",
      seoAnalytics: "Unlimited Domains and 1 Account",
      automaticContentBrief: true,
      aiTools: true,
      languages: "20+",
      collaboration: true,
      chromeExtension: true,
      wordpressPlugin: true,
      googleDocsPlugin: true,
      prioritySupport: true,
      dedicatedAccountManagerAndCustomOnboarding: false,
      customContractsAndSecurityCompliance: false,
      llmOptionality: false,
    },
    searchQueries: "Unlimited",
    aiWordCount: "Unlimited",
    display: true,
    apiUsage: "Up to 500 URLs/month",
    gscDomainLimit: Infinity,
  },
  {
    name: "Enterprise",
    id: "tier-enterprise",
    href: "#",
    monthly: {
      stripeName: "enterprise_monthly_1173",
      priceDisplay: "$1173",
      price: 1173,
      lifetimePlan: "enterprise_lifetime",
    },
    yearly: {
      stripeName: "enterprise_yearly_1173",
      priceDisplay: "$1173",
      price: 1173,
      price_per_month: "$97",
      lifetimePlan: "enterprise_lifetime_yearly",
    },
    description: "For organizations with complex needs.",
    mostPopular: true,
    documents: "Unlimited",
    users: "Unlimited",
    order: 4,
    features: {
      docSharing: true,
      wordpress: true,
      googleDocs: true,
      serpAnalysis: true,
      competitiveIntelligence: true,
      outlineBuilder: true,
      contentOptimization: true,
      multiQueryDocuments: "5",
      seoAnalytics: "Unlimited Domains and Multiple Accounts",
      automaticContentBrief: true,
      aiTools: true,
      languages: "20+",
      collaboration: true,
      chromeExtension: true,
      wordpressPlugin: true,
      googleDocsPlugin: true,
      prioritySupport: true,
      dedicatedAccountManagerAndCustomOnboarding: true,
      customContractsAndSecurityCompliance: true,
      llmOptionality: true,
    },
    searchQueries: "Unlimited",
    aiWordCount: "Unlimited",
    display: true,
    apiUsage: "Unlimited",
    gscDomainLimit: Infinity,
  },
];

export function isLegacyPlan(planName: string): boolean {
  const legacyPlanNames = [
    "solo",
    "solo_yearly",
    "solo_monthly_4",
    "solo_yearly_4",
    "team_monthly_115",
    "team_yearly_1173",
  ];
  return legacyPlanNames.includes(planName);
}

export function getTierByPlanName(planName: string) {
  // Check legacy tiers first
  const legacyTier = legacyTiers.find(
    (tier) =>
      tier.monthly.stripeName === planName ||
      tier.yearly.stripeName === planName ||
      tier.monthly.lifetimePlan === planName ||
      tier.yearly.lifetimePlan === planName
  );
  if (legacyTier) return legacyTier;

  // Check current tiers
  const currentTier = tiers.find(
    (tier) =>
      tier.monthly.stripeName === planName ||
      tier.yearly.stripeName === planName ||
      tier.monthly.lifetimePlan === planName ||
      tier.yearly.lifetimePlan === planName
  );
  return currentTier;
}
